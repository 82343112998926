import React, {useState, useRef, useEffect } from 'react';
import { useAddressAutofillCore } from '@mapbox/search-js-react';  // Import AddressAutofillCore
import $ from 'jquery';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { trackPromise } from 'react-promise-tracker';


const PartnerForm = (props) => {

  const onlyAlphabets = (event) => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      } else if (event) {
        charCode = event.which;
      }
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode === 8) || (charCode === 9) || (charCode === 13) || (charCode === 16) || (charCode === 32) || (charCode > 36 && charCode < 40) ){
      }else{
        event.preventDefault();
      }
    }
    catch (err) {
      alert(err.Description);
    }
  }
  const onlyphoneno = (e) => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      } else if (e) {
         charCode = e.which;
      }
      if ((charCode > 47 && charCode < 58)  || (charCode === 43) ){

      }else{
        e.preventDefault();
      }
    }
    catch (err) {
      alert(err.Description);
    }
  }
  const validateEmailAdd = (e) => {
    if(e.target &&e.target.value.length >0) {
      if(e.target.value.match(/^\w+(['.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/) == null){
        alert('Not a valid email address');
      }
    }
  }
  const validateConfirmAdd = (e) => {
    if(e.target.value !== document.getElementById('email').value) {
      $('input[type="submit"]').prop('disabled', true);
      $('.emailError').html('Confirm email is not matching with email address');
      $('.emailError').addClass('show');
      $('#email').addClass('error');
      $('.emailError').removeClass('hidden');
    }else{
      $('input[type="submit"]').prop('disabled', false);
      $('.emailError').html('');
      $('.emailError').addClass('hidden');
      $('#email').removeClass('error');
      $('.emailError').removeClass('show');
    }
  }
  const captchaRef = useRef(null);

  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  });

  const handleSubmit = async(e) => {

    e.preventDefault();
    const token = captchaRef.current.getValue();
    if(!token){
      alert('Please click on the reCAPTCHA box.');
    }else{
      captchaRef.current.reset();
      const dataToSubmit = $(e.target).serializeArray().reduce(function(prev, curr) {
        prev[curr.name] = curr.value;
        return prev;
      }, {});
      dataToSubmit.grecaptcharesponse = token;
      const headers = {
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3d3dy50cmF2ZWxhbmNlLmNhIiwiaWF0IjoxNzEyODA0MTYxLCJuYmYiOjE3MTI4MDQxNjEsImV4cCI6MTcxMzQwODk2MSwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiMyJ9fX0.Ah6c7zDQBOuVF-it8qSEn5aUgJ3JCN8IaMdvHOPrf8c'
      };
      let url = `${process.env.REACT_APP_API_ROOT}addLead`;
      trackPromise(
        axios.post(url,dataToSubmit,headers).then((res) => {
          const { data } = res;
          console.log(data.status);
          if(data.status === true){
            window.location.replace('https://www.travelance.ca/partner/form/thank-you/');
          }else{
            if(data.cause === "com.travelance.exception.ResourceAlreadyInUseException"){
              $('#error_message').addClass('show');
              $('#error_message').html('The email address entered already exists in our system. Please click <a href="https://mytl.travelance.ca/#/" target="_blank" style="text-decoration:underline;">here</a> to login to your account.');
              $('#email').addClass('highlights');
            }else{
              $('#error_message').addClass('show');
              $('#error_message').html(data.message);
            }
            //alert('There is some error, Please try again..');
            //$('#WebToLeadForm').trigger('reset');
          }
        })
      );
    }
  }

  const [suggestions, setSuggestions] = useState([]); // Store address suggestions
  const [query, setQuery] = useState(''); // Track user input
  const autofill = useAddressAutofillCore({ accessToken: 'pk.eyJ1IjoidGVjaC10cmF2ZWxhbmNlIiwiYSI6ImNtOTc2djZqcjA0am8yanB0emFleDA5dW4ifQ.9HsDymB4TOlEHVPaQ28Ifw',country:'CA'});

  // Function to handle input change and fetch suggestions
  const handleInputChange = async (event) => {
    const query = event.target.value;
    setQuery(query);
    // Fetch suggestions using Mapbox's `suggest` API from AddressAutofillCore
    if (query.length > 2) { // Start suggesting after 3 characters
      try {
        const results = await autofill.suggest(query,{
          sessionToken: 'test-123'
        });
        setSuggestions(results.suggestions);  // Set the suggestions to state
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);  // Clear suggestions when input is too short
    }
  };

  // Handle selecting a suggestion
  const handleSelectSuggestion = (address) => {
    setQuery(address.address_line1);  // Update the input field with the selected address
    $("#primary_address_city").val(address.place);
    $("#primary_address_state").val(address.region_code).change();
    $("#primary_address_postalcode").val(address.postcode).change();
    setSuggestions([]);  // Clear suggestions
  };








  return (
    <>
      <form  id="WebToLeadForm" action="" method="POST" name="WebToLeadForm" onSubmit={handleSubmit} >
        <input id="email_opt_in" type="hidden" name="email_opt_in" value="off" />
        <input id="client_ip" type="hidden" name="client_ip" value={ip} />
        <input type="hidden" name="trurl" value="https://www.travelance.ca/wp-admin/admin-ajax.php" />
        <h2>{props.heading}</h2>
        <label>MGA/Agency: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        {
          props.mga ?
          <>
            {
              props.mga === 'wfg'?
              (<input style={{backgroundColor:'#f4f4f4'}} className="select" id="account_name" type="text" name="account_name" value="World Financial Group (MGA)" readOnly={true}/>):""
            }
            {
              props.mga === 'efg' ?
              (<input style={{backgroundColor:'#f4f4f4'}} className="select" id="account_name" type="text" name="account_name" value="Experior Financial Group (MGA)" readOnly={true} />):""
            }
            {
              props.mga === 'gfic' ?
              (<input style={{backgroundColor:'#f4f4f4'}} className="select" id="account_name" type="text" name="account_name" value="Greatway Financial Group (MGA)" readOnly={true} />):""
            }
          </>
          :
            <>
              <input className="select" id="account_name" type="text" name="account_name" onKeyPress={ onlyAlphabets } required /><span style={{fontSize: '0.8em',color: '#7393b3'}}>MGA or General / Travel Agency you are associated with. Add Travelance if no Agency</span>
            </>
        }


        <br />
        {
          props.mga  ?
          <>
          <input id="agent_type" type="hidden" name="agent_type" value="Broker" />
          </>
          :
          <>
          <label>Type: <span className="required" style={{color: '#ff0000'}}>*</span></label>
          <br />
          <select className="select" id="agent_type" name="agent_type" type="text" required>
            <option >-- Select Agent Type--</option>
            <option value="Broker">Broker</option>
            <option value="Company Rep">Company Rep</option>
            <option value="General Agency Rep">General Agency Rep</option>
            <option value="General Agent">General Agent</option>
            <option value="MGA Rep">MGA Rep</option>
            <option value="Travel Agent">Travel Agent</option>
            <option value="Travel Agency Rep">Travel Agency Rep</option>
            <option value="Unknown">Unknown</option>
          </select>
          <br />
          </>
        }


        <label>First Name: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        <input className="select" id="first_name" type="text" name="first_name" onKeyPress={ onlyAlphabets } required />
        <br />
        <label>Middle Name:</label>
        <br />
        <input className="select" id="middle_name_c" type="text" name="middle_name_c" onKeyPress={ onlyAlphabets } />
        <br />
        <label>Last Name: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        <input className="select" id="last_name" type="text" name="last_name" onKeyPress={ onlyAlphabets } required />
        <br />
        {
          props.mga==='wfg'
          ?
          <>
            <label>WFG Code: <span className="required" style={{color: '#ff0000'}}>*</span></label>
            <br /><input className="select" id="wfg_code_c" type="text" name="wfg_code_c" required/><br />
          </>
          :""
        }
        <label>Business Phone: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        <input className="select" id="phone_mobile" type="text" name="phone_mobile" maxLength="11" onKeyPress={ onlyphoneno } required />
        <br />
        <label>Email Address: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        <input className="select" id="email" type="text" name="email1" onBlur={ validateEmailAdd } required />
        <br />
        <label>Confirm Email Address: <span className="required" style={{color: '#ff0000'}}>*</span></label>
        <br />
        <input className="select" id="email_1" type="text" name="email_2" onKeyUp={ validateConfirmAdd } required />
        <div className="emailError hidden"></div>
        <br />
          <label>Primary Street Address: <span className="required" style={{color: '#ff0000'}}>*</span></label>
          <br />
          <div className="address-autofill-container">
            <textarea className="select address-input" id="primary_address_street" type="text" name="primary_address_street" value={query} onChange={handleInputChange} required></textarea>
            {
              suggestions.length > 0 && (
                <div className="suggestion-box">
                {suggestions.map((address, index) => (
                  <div className="suggestion-item" key={index} onClick={() => handleSelectSuggestion(address)} style={{padding: '8px',cursor: 'pointer',borderBottom: index === suggestions.length - 1 ? 'none' : '1px solid #ddd',}}>
                  {address.place_name}
                  </div>
                ))}
                </div>
              )
            }
          </div>
          <br />
          <label>Primary Address City: <span className="required" style={{color: '#ff0000'}}>*</span></label>
          <br />
          <input className="select" id="primary_address_city" type="text" name="primary_address_city" required />
          <br />
          <label>Primary Address Province: <span className="required" style={{color: '#ff0000'}}>*</span></label>
          <br />
          <select className="select" id="primary_address_state" type="text" name="primary_address_state" required>
            <option>-- Select Province --</option>
            <option value="AB">AB</option>
            <option value="BC">BC</option>
            <option value="MB">MB</option>
            <option value="NB">NB</option>
            <option value="NL">NL</option>
            <option value="NS">NS</option>
            <option value="NT">NT</option>
            <option value="NU">NU</option>
            <option value="ON">ON</option>
            <option value="PE">PE</option>
            <option value="QC">QC</option>
            <option value="SK">SK</option>
            <option value="YT">YT</option>
          </select>
          <br />
          <label>Postal Code: <span className="required" style={{color: '#ff0000'}}>*</span></label>
          <br />
          <input className="select" id="primary_address_postalcode" type="text" name="primary_address_postalcode" required />
        <br />
        <input id="email_opt_in" type="checkbox" name="email_opt_in" /> By checking this box, you agree to receive future marketing emails.
        <br />
        <br />
        <ReCAPTCHA sitekey="6Leqq-kUAAAAAH5ekaYckwmGLXuDcDp9U2X0ipip" ref={captchaRef} />
        <br />
        <br />
        <input className="btn btn-primary" type="submit" name="Submit" value="Submit" />
        <table>
          <tbody>
            <tr>
              <td style={{display: 'none'}}><input id="campaign_id" type="hidden" name="campaign_id" value="001979bc-ae88-11eb-b294-022500f56390" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="redirect_url" type="hidden" name="redirect_url" value="https://www.travelance.ca/partner/form/thank-you/" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="redirectRequestType" type="hidden" name="redirectRequestType" value="GET" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="redirectIncludeParams" type="hidden" name="redirectIncludeParams" value="1" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="assigned_user_id" type="hidden" name="assigned_user_id" value="1" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="team_id" type="hidden" name="team_id" value="1" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="team_set_id" type="hidden" name="team_set_id" value="1" /></td>
            </tr>
            <tr>
              <td style={{display: 'none'}}><input id="req_id" type="hidden" name="req_id" value="last_name;email;" /></td>
            </tr>
          </tbody>
        </table>
        <div id="error_message"></div>
      </form>
    </>
  );
}

export default PartnerForm;
